
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import Dashboard from '@/components/Dashboard.vue'
import StopSearch from '@/components/StopSearch.vue'
import Modal from '@/components/Modal.vue'
import GenericError from '@/components/GenericError.vue'
import useBasket from '@/composables/useBasket'

export default defineComponent({
  components: { Dashboard, StopSearch, Modal, GenericError },
  setup () {
    const router = useRouter()
    const { showBootMessage, clearBasket } = useBasket()

    const tryAgain = () => {
      clearBasket()
      router.push({ name: 'Journey Search' })
    }
    return {
      tryAgain,
      showBootMessage,
      clearBasket
    }
  }
})
